<template>
  <van-nav-bar
    title="商品归属"
    left-arrow
    @click-left="onGoBack"
    />
  <div v-if="proinfo.status==6">
    <h2 style="color:red">提示，该产品为问题产品，请拒绝购买使用，否则后果自负！</h2>
    <h2>{{gootherhref()}}</h2>
  </div>
  <div v-else-if=" proinfo.queryTime>=20 ">
    <h2 style="color:red">提示，该产品扫码频繁，请谨慎购买！</h2>
  </div>
  <div v-else>
  <van-cell title="追溯码为："  :value=proinfo.proCode />
  <van-card
      :title="proinfo.proName"
      :price="proinfo.proPrice"
      :desc="proinfo.productId"
      :thumb="proinfo.proPic"
    >
  </van-card>
  <van-cell-group>
    <van-cell title="商品归属"  />
    <van-cell title="归属人:" :value="proinfo.userName"  />
    <van-cell title="手机:" :value="proinfo.userPhoneNum"  />
  </van-cell-group>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import sha1 from "sha1";
import { uuid } from 'vue-uuid';
import { useUserStore } from '@/store/user.ts'
import axios from 'axios'

const userStore=useUserStore()
const BASE_URL = process.env.VUE_APP_API_PORT_BASE_URL

export default {
  components: {
  },
  data() {
    return {
      qrc_code:'',
      proinfo:{}
    };
  },

  mounted(){
    this.onScan()
    
  },
   
    methods: {
    
    //启动扫码
    onScan(){
        const _this = this 
        axios.get("weixinJsApiTicket.txt")
        .then(response => {
          let jsapi_ticket=response.data.jsApiTicket
          let url=location.href.split("#")[0]
          let timestamp = ''+(new Date().getTime());
          let nonceStr = uuid.v1()
          let sinstr='jsapi_ticket='+jsapi_ticket+'&noncestr='+nonceStr+'&timestamp='+timestamp+'&url='+url
          let signature=sha1(sinstr)
          
          wx.config({
            debug: false,
            appId: 'wxcaf67703b1fab571',
            timestamp: timestamp,
            nonceStr:nonceStr,
            //nonceStr: res.data.jsSdkUiPackage.nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
        wx.error(function (res) {
          Dialog.alert({
            title: "提示",
            message: res.errMsg
          }).then(() => {});
        });
        wx.ready(function () {
            wx.checkJsApi({
              jsApiList: ['scanQRCode'],
              success: function (res) {
              }
            });
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                      _this.qrc_code = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                      _this.getproinfo()
                     /* _this.$router.push({   //跳转到什么页面
                        name: "exwarehouse"
                      });*/
                }
            });
        });
    },

    getproinfo(){
      let params = {data: {}}
      params={
        'codeSite':this.qrc_code
        }
      console.log(params)
      //let tokens={'Authorization':userStore.userInfo.token}
      //axios.post(BASE_URL+'/code/queryCodeMessage',params,{headers:tokens})
      axios.post(BASE_URL+'/code/getCodeInfo',params)
      .then(res=>{
        this.proinfo={
          'proCode':res.data.data.proCode,
          'proName':res.data.data.proName,
          'proPic':BASE_URL+res.data.data.proPic,
          'proPrice':res.data.data.proPrice,
          'productId':'货号：'+res.data.data.productId,
          'userName':res.data.data.userName,
          'userPhoneNum':res.data.data.userPhoneNum,
          'status':res.data.data.status,
          'queryTime':res.data.data.queryTime
        }
      })
    }
  },
  //跳转
  gootherhref(){
    window.location.href="http://hrstltd.cn"
  }
}

</script>